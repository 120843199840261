@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.woff2') format('woff2'),
       url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.woff') format('woff');
  font-display: swap;
  cross-origin: anonymous;
}

/* 글로벌 글꼴 설정 */
body {
  font-family: 'Pretendard', sans-serif;
}
